import React from 'react'
import Menu from '../components/Menu'

const Test = ({word}) => {
    return <div>
        {word}
        <Menu />
    </div>
}

export default Test